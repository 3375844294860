import { useRef } from 'react';
import SubHeader from '../header/SubHeader';

export const SearchBar = ({
  className = '',
  style = {},
  onSearch,
  isOnHoverExpend = false,
}) => {
  const inputRef = useRef(null);
  // const [input, setInput] = useState(null);
  return (
    <div
      className={`search_bar shadow ${
        isOnHoverExpend ? 'on-hover-expand' : ''
      } ${className}`}
      style={style}
      onClick={() => {
        inputRef.current.focus();
      }}
    >
      <div
        className='search_bar_form'
        style={{
          margin: 'auto',
          //  maxWidth: 300
        }}
      >
        <div className='searc_btn'>
          <img alt='search' src='images/icon/search.svg' />
        </div>
        <input
          ref={inputRef}
          // value={input}
          type='text'
          placeholder='Enter token name or token symbol'
          name='search2'
          onChange={onSearch}
        />
      </div>
    </div>
  );
};

const SaleLayout = ({
  title,
  renderFilter,
  tabs = [],
  activeTab,
  onTabClick,
  children,
  // toggleFilter,
  renderSearch,
}) => {
  return (
    <div className='d-flex gap-3 gap-md-4'>
      {/* left - content */}
      <div className='w-100'>
        {/* header */}
        <div className='d-block d-md-flex gap-2 flex-wrap align-items-center justify-content-between mb-3'>
          <div className='mb-3 m-md-0'>
            <SubHeader title={title} marginBottom={0} />
          </div>
          <div className='d-flex align-items-center justify-content-end gap-2 gap-sm-3 flex-grow-1 mb-3 mb-md-0'>
            {renderSearch && renderSearch}
            {renderFilter && (
              <>
                {/* <div
                  className='text-white d-flex align-items-center gap-2 pointer d-xl-none'
                  onClick={toggleFilter}
                  style={{
                    height: 'fit-content',
                  }}
                  // data-bs-toggle='offcanvas'
                  // data-bs-target='#offcanvasWithBothOptions'
                  // aria-controls='offcanvasWithBothOptions'
                >
                  <i className='fa fa-filter icon'></i>
                  Filters
                </div> */}
                <button
                  className='btn-filter btn btn-primary d-none align-items-center gap-2 d-xxl-none'
                  type='button'
                  data-bs-toggle='offcanvas'
                  data-bs-target='#offcanvasWithBothOptions'
                  aria-controls='offcanvasWithBothOptions'
                >
                  <i className='fa fa-filter icon'></i>{' '}
                  <span className='d-none d-sm-inline'>Filters</span>
                </button>
              </>
            )}

            <div
              className='offcanvas offcanvas-start bg-secondary'
              style={{
                width: '300px',
              }}
              data-bs-scroll='true'
              tabindex='-1'
              id='offcanvasWithBothOptions'
              aria-labelledby='offcanvasWithBothOptionsLabel'
            >
              <div className='offcanvas-header'>
                <h5
                  className='offcanvas-title text-white'
                  id='offcanvasWithBothOptionsLabel'
                >
                  Filters
                </h5>
                <button
                  type='button'
                  className='btn-close btn-close-white'
                  data-bs-dismiss='offcanvas'
                  aria-label='Close'
                ></button>
              </div>
              <div className='offcanvas-body px-0'>{renderFilter}</div>
            </div>
          </div>
        </div>
        {/* tabs */}
        <div className='presales_tabs'>
          <ul className='position-relative nav nav-tabs w-100 flex-nowrap overflow-x-auto overflow-y-hidden mb-1 mb-lg-0'>
            {tabs.map((tab, index) => {
              return (
                <li key={index} className='nav-item'>
                  <button
                    className={`nav-link text-nowrap ${activeTab === tab.id && 'active'}`}
                    data-bs-toggle='tab'
                    href={tab.href}
                    onClick={() => {
                      onTabClick(tab.id);
                    }}
                  >
                    {tab.label}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>

        {/* tab content */}
        <div className='my-4'>{children}</div>
      </div>
      {/* right - filter */}
      {renderFilter && (
        <div className='filter-container card px-0 py-4'>
          {/* heading */}
          <div className='px-4 d-flex justify-content-between align-items-start'>
            <h6
              className='text-muted fw-normal fs-6 mb-4 '
              style={{
                // fontSize: '16px',
                // lineHeight: '24px',
                // marginBottom: '35px',
              }}
            >
              Filters
            </h6>
            {/* <button
            type='button'
            onClick={() => setOpenFilter(false)}
            className='btn-close btn-close-white d-block d-xl-none'
            aria-label='Close'
            ></button> */}
          </div>
          {renderFilter}
        </div>
      )}
    </div>
  );
};

export default SaleLayout;
