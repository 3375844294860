import { useEffect } from 'react';
import { homeInfoService } from '../../services/API/api.service';
import { useState } from 'react';
import {
  APP_NAME_TITLE,
  BASE_URL,
  networkImagesByChain,
} from '../../_constant';
import { getHeighlightedPresalesAction } from '../../redux/apiActions/api.action';
import { useNavigate } from 'react-router-dom';
import { SyncLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import ReactApexChart from 'react-apexcharts';
import Container from '../../Components/Layouts/Container';

const ShortcutListCard = ({
  title = '',
  description = '',
  imageUrl = 'images/stats/presales.svg',
  navigateUrl = '',
}) => {
  return (
    <button
      type='button'
      className='custom_card stats_card stats_presale_card py-4 px-4'
      onClick={(e) => {
        window.open(BASE_URL + navigateUrl);
      }}
    >
      <div className='muibox_img mb-3'>
        <img src={imageUrl} alt='img' />
      </div>
      <div className='text-center'>
        <h5 className='mb-2 fs-5'>{title}</h5>
        <p className='mb-0 fs-6'>{description}</p>
      </div>
    </button>
  );
};

const chartWithHeightStyle = {
  width: 60,
  height: 30,
};

const StatsCard = ({
  loading = true,
  title = '',
  value = '',
  percentage,
  graphData = [],
}) => {
  return (
    <div className='px-3 py-2'>
      <div
        className={`d-flex justify-content-between align-items-end gap-2 mb-2 ${
          loading ? 'placeholder-glow' : ''
        }`}
      >
        {loading ? (
          <span className='placeholder col-4'></span>
        ) : (
          <h2 className='fs-2'>{value}</h2>
        )}
        {loading ? (
          <span className='placeholder' style={chartWithHeightStyle}></span>
        ) : (
          graphData &&
          graphData.length > 0 && (
            <ReactApexChart
              // width={60}
              // height={30}
              {...chartWithHeightStyle}
              options={{
                colors: ['#0CAF60'],
                chart: {
                  sparkline: {
                    enabled: true,
                  },
                  toolbar: {
                    show: false,
                  },
                  type: 'area',
                  zoom: {
                    enabled: false,
                  },
                  stackOnlyBar: false,
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  curve: 'smooth',
                  width: 1.5,
                  // fill: '#0CAF60',
                },
                markers: {
                  size: 0,
                },
                tooltip: {
                  shared: true,
                  hideEmptySeries: false,
                  x: {
                    format: 'dd/MM/yy',
                    show: false,
                  },
                  enabled: false,
                },
                legend: {
                  show: false,
                },
                yaxis: {
                  show: false,
                  axisBorder: {
                    show: false,
                  },
                  labels: {
                    show: false,
                  },
                },
                xaxis: {
                  axisBorder: {
                    show: false,
                  },
                  axisTicks: {
                    show: false,
                  },
                  labels: {
                    show: false,
                  },

                  type: 'datetime',
                  categories: graphData.map((item) => item.x),
                },
                plotOptions: {
                  area: {
                    fillTo: 'end',
                  },
                },
                // fill: {
                //   type: 'gradient',
                //   gradient: {
                //     shadeIntensity: 1,
                //     opacityFrom: 0.7,
                //     opacityTo: 0.9,
                //     stops: [0, 100],
                //   },
                // },
              }}
              series={[
                {
                  name: 'graph',
                  data: graphData.map((item) => item.y),
                },
              ]}
              type='area'
            />
          )
        )}
      </div>
      <div className='card_second_section_text w-100 d-flex justify-content-between align-items-start gap-2'>
        <p
          className='mb-0 fs-7 fw-semibold'
          style={{
            color: '#C3CDDB',
          }}
        >
          {title}
        </p>
        {loading ? (
          <span className='placeholder placeholder-sm col-2'></span>
        ) : (
          <p
            className='m-0 fs-8'
            style={{
              color: '#C3CDDB',
            }}
          >
            +{percentage}%
          </p>
        )}
      </div>
    </div>
  );
};

const shortcutList = [
  {
    id: '1',
    title: 'Presales',
    description: 'Explores presales of any size and kind',
    imageUrl: 'images/stats/presales.svg',
    navigateUrl: 'presales',
  },
  {
    id: '2',
    title: 'Staking Pools',
    description:
      'Explore the staking pools and enjoy passive rewards from multiple projects',
    imageUrl: 'images/stats/staking.svg',
    navigateUrl: 'stakes',
  },
  {
    id: '3',
    title: 'Tokens',
    description: 'Create your own Token with Audit included',
    imageUrl: 'images/stats/token.svg',
    navigateUrl: 'create-token',
  },
  {
    id: '4',
    title: 'Airdrop',
    description: 'Free tool for airdrops',
    imageUrl: 'images/stats/airdrop.svg',
    navigateUrl: 'airdrop',
  },
  {
    id: '5',
    title: 'Create Presale',
    description:
      'Create presale for you own project and enjoy all the benefits Launchpad offers',
    imageUrl: 'images/stats/create-presale.svg',
    navigateUrl: 'create',
  },
  {
    id: '6',
    title: 'Create Stake',
    description: 'Create your own rewarding system',
    imageUrl: 'images/stats/create-stake.svg',
    navigateUrl: 'create-stake',
  },
  // {
  //   id: '7',
  //   title: 'Scanner Agent',
  //   description: 'Become a Launchpad Agent and earn up to 50% commission',
  //   imageUrl: 'images/stats/agent.svg',
  // },
  // {
  //   id: '8',
  //   title: 'Scanner Alpha',
  //   description: "Join Launchpad's Alpha club by holding 180,000 GEMS",
  //   imageUrl: 'images/stats/alpha.svg',
  // },
];

export default function Home() {
  document.title = APP_NAME_TITLE;

  const [info, setInfo] = useState({});

  const [loading, setLoading] = useState(false);

  const homeInfoAPI = async () => {
    try {
      const { data } = await homeInfoService();
      setInfo(data?.data);
    } catch (error) {
      toast.error('Something went wrong, Please try again', {
        toastId: 'swwPTid',
      });
    }
  };

  const [presales, setPresales] = useState([]);

  const getPresales = async () => {
    try {
      setLoading(true);
      const { data } = await getHeighlightedPresalesAction();
      setPresales(data?.data);
    } catch (err) {
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  };

  useEffect(() => {
    homeInfoAPI();
    getPresales();
  }, []);

  const navigate = useNavigate();
  console.log(presales, 'presales');

  return (
    <Container id='homepage'>
      {/* {loading || Object.keys(info).length === 0 ? (
        <div className='d-flex justify-content-center align-items-center vh-100'>
          <SyncLoader color='#3498DB' />
        </div>
      ) : ( */}
      <>
        {/* <div className='row d-none'>
          <div className='stats_runing_bar d-flex align-items-center'>
            <div className='d-flex justify-content-between align-items-center breaking-news w-100'>
              <marquee
                className='news-scroll'
                behavior='scroll'
                direction='left'
                onmouseover='this.stop();'
                onmouseout='this.start();'
                scrollamount={5}
              >
                <ul className='d-flex align-items-center gap-4'>
                  {presales?.map((presale, index) => (
                    <li
                      onMouseEnter={() => {
                        const marquee = document.querySelector('.news-scroll');
                        marquee.stop();
                      }}
                      onMouseLeave={() => {
                        const marquee = document.querySelector('.news-scroll');
                        marquee.start();
                      }}
                    >
                      <button
                        type='button'
                        className='runing_bar_button d-flex align-items-center'
                        onClick={() => {
                          navigate(
                            `${BASE_URL}presale?presale_id=${presale?.preSale}&chainId=${presale?.chain}`
                          );
                        }}
                      >
                        <img
                          src={networkImagesByChain[presale?.chain]}
                          className='img-fluid me-md-2'
                          alt='icon'
                          width={40}
                        />
                        <div className>
                          <h5 className='text-white'>
                            {presale?.sale_without_token == 1 &&
                            presale?.startTime == '0'
                              ? presale?.sale_title
                              : presale?.launch_stealth_sale == true
                              ? presale?.sale_title
                              : presale?.name}
                          </h5>
                          <p className='mb-0'>
                            <img
                              src='images/icon/hot.png'
                              className='img-fluid me-2'
                              alt='icon'
                            />
                            {presale?.token_symbol}
                          </p>
                        </div>
                      </button>
                    </li>
                  ))}
                </ul>
              </marquee>
            </div>
          </div>
        </div> */}
        {/* <div className="row">
    <div className="text-center mt-4">
        <img src="images/stats/logo-etherscan.svg" alt="icon" width={300} />
    </div>
</div> */}
        <div className='row'>
          {/* Tab panes */}
          <div className='col-12 col-lg'>
            <div className='card p-0 overflow-hidden home_banner_container'>
              <div className='text-center'>
                <img
                  src='images/home_logo.png'
                  alt='logo'
                  className='home_logo img-fluid mt-4 mt-sm-5'
                  loading='lazy'
                />
                {/* <img
                  src='images/hp_gradient.png'
                  alt='hp_gradient'
                  className='w-100 position-absolute'
                  loading='lazy'
                />
                <img
                  src='images/wave_background.png'
                  alt='img'
                  className='w-100'
                  loading='lazy'
                /> */}
              </div>
              <div className='home_banner-shortcut_contain mt-2'>
                <div
                  className='px-1 px-lg-3 mx-auto'
                  style={{
                    maxWidth: '620px',
                    //   transform: 'translateY(-10px)',
                  }}
                >
                  <h1 className='text-center text-white fw-semibold ff-poppins mb-3 fs-11'>
                    Launch, Lock, and Grow Your Crypto
                  </h1>
                  <p className='text-center ff-poppins fs-55 fw-medium mb-5'>
                    Launch tokens, secure liquidity, organize airdrops and
                    access vital tools on one convenient platform. Streamline
                    your crypto project with our user-friendly launchpad.
                  </p>
                </div>
                <div className='px-lg-3 px-2 py-4 row row-cols-1 row-cols-xxxl-3 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-2 row-cols-md-2 row-cols-sm-2 g-3'>
                  {shortcutList.map((stats) => (
                    <div className='col'>
                      <ShortcutListCard
                        title={stats.title}
                        description={stats.description}
                        imageUrl={stats.imageUrl}
                        navigateUrl={stats.navigateUrl}
                      />
                    </div>
                  ))}
                  {/* <div className="col">
            <button type="button" className="custom_card stats_presale_card p-4 green_card d-flex align-items-start"
                onClick={(e) => {
                    window.open(BASE_URL + 'presales')
                }
                }
            >
                <div className="text-start">
                    <h3>Presales</h3>
                    <p className="mb-0 text-white">Explores presales of any size and kind</p>
                </div>
                <div className="muibox_img">
                    <img src="images/stats/presales.png" alt="img" />
                </div>
            </button>
        </div>
        <div className="col">
            <button type="button" className="custom_card stats_presale_card p-4 purpal_card d-flex align-items-start"
                onClick={(e) => {
                    window.open(BASE_URL + 'stakes')
                }
                }
            >
                <div className="text-start">
                    <h3>Staking Pools</h3>
                    <p className="mb-0 text-white">Explore the staking pools and enjoy passive rewards from multiple projects</p>
                </div>
                <div className="muibox_img">
                    <img src="images/stats/staking.png" alt="img" />
                </div>
            </button>
        </div>
        <div className="col">
            <button type="button" className="custom_card stats_presale_card p-4 blue_card d-flex align-items-start"
                onClick={(e) => {
                    window.open(BASE_URL + 'create-token')
                }
                }
            >
                <div className="text-start">
                    <h3>Tokens</h3>
                    <p className="mb-0 text-white">Create your own Token with Audit included</p>
                </div>
                <div className="muibox_img">
                    <img src="images/stats/token.png" alt="img" />
                </div>
            </button>
        </div>
        <div className="col">
            <button type="button" className="custom_card stats_presale_card p-4 orange_card d-flex align-items-start"
                onClick={(e) => {
                    window.open(BASE_URL + 'airdrop')
                }
                }
            >
                <div className="text-start">
                    <h3>Airdrop</h3>
                    <p className="mb-0 text-white">Free tool for airdrops</p>
                </div>
                <div className="muibox_img">
                    <img src="images/stats/airdrop.png" alt="img" />
                </div>
            </button>
        </div>
        <div className="col">
            <button type="button" className="custom_card stats_presale_card p-4 blue_card d-flex align-items-start"
                onClick={(e) => {
                    window.open(BASE_URL + 'create-presale')
                }
                }
            >
                <div className="text-start">
                    <h3>Create Presale</h3>
                    <p className="mb-0 text-white">Create presale for you own project and enjoy all the benefits Launchpad offers</p>
                </div>
                <div className="muibox_img">
                    <img src="images/stats/create.png" alt="img" />
                </div>
            </button>
        </div>
        <div className="col">
            <button type="button" className="custom_card stats_presale_card p-4 orange_card d-flex align-items-start"
                onClick={(e) => {
                    window.open(BASE_URL + 'create-stake')
                }
                }
            >
                <div className="text-start">
                    <h3>Create Stake</h3>
                    <p className="mb-0 text-white">Create your own rewarding system</p>
                </div>
                <div className="muibox_img">
                    <img src="images/stats/create.png" alt="img" />
                </div>
            </button>
        </div>
        <div className="col">
            <button type="button" className="custom_card stats_presale_card p-4 green_card d-flex align-items-start">
                <div className="text-start">
                    <h3>Scanner Agent</h3>
                    <p className="mb-0 text-white">Become a Launchpad Agent and earn up to 50% commission</p>
                </div>
                <div className="muibox_img">
                </div>
            </button>
        </div>
        <div className="col">
            <button type="button" className="custom_card stats_presale_card p-4 red_card d-flex align-items-start">
                <div className="text-start">
                    <h3>Scanner Alpha</h3>
                    <p className="mb-0 text-white">Join Launchpad's Alpha club by holding 180,000 GEMS</p>
                </div>
                <div className="muibox_img">
                    <img src="images/stats/alpha.png" alt="img" />
                </div>
            </button>
        </div> */}
                </div>
              </div>
            </div>
          </div>

          <div
            className='col-12 col-md-12 col-lg-auto mx-auto mt-4 mt-lg-0'
            // style={{
            //   // minWidth: '300px',
            //   width: 'var(--w-home_page_stats)',
            // }}
          >
            <div
              className='home_stats_card card p-0 py-3 mx-auto'
              style={{
                height: 'fit-content',
                position: 'sticky',
                top: '96px',
                width: 'var(--w-home_page_stats)',
              }}
            >
              <h6
                className='fs-6 text-start px-3 py-2 fw-light'
                style={{
                  color: '#C3CDDB',
                }}
              >
                Stats
              </h6>
              <hr className='' />

              <StatsCard
                loading={loading}
                title='Total Projects'
                value={Number(info?.total_projects)?.toLocaleString()}
                percentage={Number(
                  Number(info?.projectPercentageIncreased || 0).toFixed(3)
                )?.toLocaleString()}
                graphData={info?.projectGraphData || []}
              />
              <hr />
              <StatsCard
                loading={loading}
                title='Total Generated Tokens'
                value={Number(info?.total_tokens)?.toLocaleString()}
                percentage={Number(
                  Number(info?.tokenPercentageIncreased || 0).toFixed(3)
                )?.toLocaleString()}
                graphData={info?.tokenGraphData || []}
              />
              <hr />
              <StatsCard
                loading={loading}
                title='Project Raise Success Rate'
                value={Number(info?.success_ratio)?.toFixed(2) + '%'}
                percentage={Number(
                  Number(info?.projectFinalizePercentageIncreased || 0).toFixed(
                    3
                  )
                )?.toLocaleString()}
                graphData={info?.projectFinalizedGraphData || []}
              />
            </div>
          </div>
        </div>

        {/* <div className="row mt-5">
    <h2 className="text-white text-center mt-5 mb-4">Partners</h2>
    <div className="stats_runing_bar d-flex align-items-center">
        <div className="d-flex justify-content-between align-items-center breaking-news w-100">
            <marquee className="news-scroll" behavior="scroll" direction="left" onmouseover="this.stop();" onmouseout="this.start();" scrollamount={5}>
                <ul className="d-flex align-items-center gap-4 partners_img_stats">
                    <li>
                        <a href="javascript:void(0)" className="runing_bar_button d-flex align-items-center">
                            <img src="images/icon/shibelon.png" className="img-fluid" alt="icon" width={40} />
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" className="runing_bar_button d-flex align-items-center">
                            <img src="images/icon/shibelon.png" className="img-fluid" alt="icon" width={40} />
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" className="runing_bar_button d-flex align-items-center">
                            <img src="images/icon/shibelon.png" className="img-fluid" alt="icon" width={40} />
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" className="runing_bar_button d-flex align-items-center">
                            <img src="images/icon/shibelon.png" className="img-fluid" alt="icon" width={40} />
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" className="runing_bar_button d-flex align-items-center">
                            <img src="images/icon/shibelon.png" className="img-fluid" alt="icon" width={40} />
                        </a>
                    </li>
                </ul>
            </marquee>
        </div>
    </div>
</div> */}
      </>
      {/* )} */}
    </Container>
  );
}
