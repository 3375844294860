import React from 'react';
import { createMenusLeft, createMenusRight, createMenu } from '../../_constant';
import { Link } from 'react-router-dom';

const CreateModal = () => {
  //   function closeModal() {
  //     // alert('Modal closed');
  //     const closeButton = document.querySelector('.btn-close');
  //     console.log(closeButton, 'closebtn');
  //     closeButton.click();
  //   }

  return (
    <div className='modal' id='createModal'>
      <div
        className='modal-dialog modal-dialog-centered  modal-dialog-scrollable'
        style={{
          maxWidth: 'var(--max-w-create-modal)',
        }}
      >
        <div className='modal-content'>
          {/* Modal Header */}
          <div class='modal-header'>
            <h5 class='modal-title'>Create</h5>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
            ></button>
          </div>
          <div className='modal-body'>
            <div id='myDropdown' className='dropdown-content'>
              <div className='row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-3'>
                {Object.keys(createMenu).map((value, key) => (
                  <div className='col'>
                    <ul className='gap-3 d-grid create_model_anchor'>
                      <li
                        className='create_button'
                        key={value}
                        data-bs-dismiss='modal'
                      >
                        <Link
                          to={createMenu[value]}
                          //   onClick={() => closeModal()}
                        >
                          {value}
                        </Link>
                      </li>
                    </ul>
                  </div>
                ))}
                <div className='col'></div>
              </div>
              {/* <div className='row row-cols-1 row-cols-sm-2'>
                <div className='col'>
                  <ul className='gap-3 d-grid create_model_anchor mb-3'>
                    {Object.keys(createMenusLeft).map((value, key) => (
                      <li
                        className='create_button'
                        key={value}
                        data-bs-dismiss='modal'
                      >
                        <Link
                          to={createMenusLeft[value]}
                          aria-disabled={true}
                          //   onClick={() => closeModal()}
                        >
                          {value}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className='col'>
                  <ul className='gap-3 d-grid create_model_anchor'>
                    {Object.keys(createMenusRight).map((value, key) => (
                      <li
                        data-bs-dismiss='modal'
                        className='create_button'
                        key={value}
                      >
                        <Link
                          to={createMenusRight[value]}
                          //   onClick={() => closeModal()}
                        >
                          {value}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateModal;
