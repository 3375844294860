import React, { useEffect, useState, useCallback, useRef } from 'react';
import PrescaleHeader from './PrescaleHeader';
import {
  FavouriteAction,
  GetPresaleAction,
  reminderAction,
  getPresaleReminderDetails,
  deletePresalesReminder,
  deleteonePresalesReminder,
} from '../../redux/apiActions/api.action';
import PreSale from '../../_constant/PreSale.json';
import FairLaunch from '../../_constant/FairLaunch.json';
import PersaleCountDown from '../../Components/CountDown/PersaleCountDown';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import { getAccount, watchNetwork, getNetwork } from '@wagmi/core';
import { useAccount, useContractReads } from 'wagmi';
import { ethers } from 'ethers';
import { useNavigate } from 'react-router';
import {
  APP_NAME_TITLE,
  BASE_URL,
  PRESALE_STATUS,
  PerkCategories,
  SALE_TYPE,
  networkImagesByChain,
  presaleFilterByOptions,
  presaleTabs,
} from '../../_constant';
import { useLocation } from 'react-router-dom';
import { components } from 'react-select';
import Pagination from '../../Components/Pagination/Pagination';
import PaginationOver from '../../Components/Pagination/PaginationOverload';
import { useDispatch } from 'react-redux';
import Progressbar from './Progressbar';
import FairPorgressbar from './FairProgressbar';
import { PresaleProgInfo } from './PresaleProgInfo';
import { FairProgInfo } from './FairProgInfo';
import Trending from './Trending';
import QuickView from './Quickview';
import { SyncLoader } from 'react-spinners';
import Banner from '../PresaleDetails/Banner';
import _lodash from 'lodash';
import { Tooltip } from '@mui/material';
import config from '../../config';
import Chip from '../../Components/Chip';
import Filter from '../../Components/Filter';
import SaleLayout, { SearchBar } from '../../Components/Layouts/SaleLayout';
import PresaleCardShimmer from '../../Components/shimmers/PresaleCardShimmer';
import PresaleCard from '../../Components/presale/PresaleCard';
import Container from '../../Components/Layouts/Container';

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = 'transparent';
  if (isFocused) bg = 'transparent';
  if (isActive) bg = 'transparent';

  const style = {
    alignItems: 'center',
    backgroundColor: bg,
    color: 'inherit',
    display: 'flex ',
    width: '100%',
    zIndex: 999,
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type='checkbox' className='select_checkbox' checked={isSelected} />
      {children}
    </components.Option>
  );
};

// const allOptions = [
//   { value: "56", label: "BNB" },
//   // { value: "97", label: "tBNB" },
//   // { value: "80001", label: "Matic" }
// ];

export default function Presale({ notificationToken }) {
  // const {chains} = useNetwork()
  // console.log(chains, 'chains')
  // let allOptions = chains?.length >= 0 ? chains?.map(chain => ({ value: chain.id, label: chain.nativeCurrency.symbol })) : defaultAllOptions
  let allOptions = config.ACTIVE_CHAINS.map((chain) => ({
    value: chain.id,
    label: chain.nativeCurrency.symbol,
    subLabel: chain.nativeCurrency.symbol,
    iconURL: String(networkImagesByChain[chain.id]),
  }));
  const [list, setList] = useState([]);
  const [selectedValues, setSelectedValues] = useState(
    allOptions.map((item) => item.value)
  );
  const { chain } = getNetwork();
  const {
    data: fundRaisedData,
    isError,
    isLoading,
  } = useContractReads({
    contracts: list?.map((item) => ({
      address: item.preSale,
      abi: SALE_TYPE.isPresaleOrPrivateSale(item?.saleType) ? PreSale?.abi : FairLaunch.abi,
      functionName: 'fundRaised',
      chainId: item.chain,
    })),
  });

  const location = useLocation();
  const { isDisconnected, isConnected } = useAccount();
  const [totalData, setTotalData] = useState(0);
  const [currentNetwork, _currentNetwork] = useState(selectedValues);
  const account = getAccount();

  // useEffect(() => {
  //   window.ethereum.on("chainChanged", (chainId) => {
  //     _currentNetwork(chainId)
  //   });

  // }, []);

  useEffect(() => {
    watchNetwork((network) =>
      // console.log(network))
      _currentNetwork(network.chain)
    );
  }, []);

  const [presales, setPresales] = useState([]);

  const [activeTab, _activeTab] = useState('all_products');
  // const auth = useSelector((state) => state?.user?.search);

  // const [search, _search] = useState(auth);
  const search = useRef('');
  const [sortBy, setSortBy] = useState('no');
  const [filterBy, setFilterBy] = useState('all');
  const handleSortChange = ({ target }) => {
    handleChange(undefined, undefined, target.value, undefined);
  };
  const handleFilterChange = ({ target }) => {
    setFilterBy(target.value);
    handleChange(undefined, target.value, undefined, undefined);
  };

  // const [activeTab, setActiveTab] = useState();

  function setShowTab(tab) {
    if (tab != activeTab) {
      setPresales([]);
    }
    // setIsLoading(true)
    search.current = '';
    _activeTab(tab);
    handleChange(undefined, undefined, undefined, undefined, tab);
    setCurrentPage(1);
    // setTimeout(() => {
    //   setIsLoading(false)
    // }, 2000)
  }

  const [loading, setIsLoading] = useState(false);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);

  const FavouriteFunction = async (id, ido) => {
    if (account.isConnected == false) {
      toast.error('Login first', { toastId: 'loginFirstTid' });
    } else {
      toggleHeartColor(`heart-button-${id}`);
      let body = {
        presale_id: id,
        wallet: account.address,
        chain: chain?.id,
        ido: ido,
      };
      await FavouriteAction(body).then((response) => {
        toast.success(response?.message || response?.data?.message);
        // getPresaleFunction();
      });
    }
  };

  const toggleHeartColor = (toggleIcon) => {
    const heartIcon = document.getElementById(toggleIcon);
    // Toggle the color style
    heartIcon.style.color = heartIcon.style.color === 'red' ? '' : 'red';
  };

  // Calculate pagination variables
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = presales?.slice(indexOfFirstItem, indexOfLastItem);

  // Change page

  const navigate = useNavigate();

  const handlePageChange = (page) => {
    // setCurrentPage(page);
    handleChange(page, undefined, undefined, undefined);
    setCurrentPage(page);
  };
  // Generate pagination buttons

  useEffect(() => {
    setPresales([]);
    const urlSearchParams = new URLSearchParams(location.search);
    const page = urlSearchParams.get('page') || '1';
    const filterBy = urlSearchParams.get('filterBy') || 'all';
    const sortBy = urlSearchParams.get('sortBy') || 'no';
    const networks = urlSearchParams.get('networks') || selectedValues;
    const activatedTab = urlSearchParams.get('tab') || 'all_products';

    setCurrentPage(Number(page));
    setFilterBy(filterBy);
    _activeTab(activatedTab);
    setSortBy(sortBy);
    _currentNetwork(networks);

    const getPresaleFunction = () => {
      let activeInfo = { activeTab: activatedTab };
      const getPre = async () => {
        setIsLoading(true);

        const urlSearchParams = new URLSearchParams(location.search);
        const mode = urlSearchParams.get('mode');

        let params = {
          wallet: account?.address,
          chain_id: selectedValues || chain?.id,
          search: search?.current,
          sortBy: sortBy,
          filterBy: filterBy,
          pageSize: itemsPerPage,
          currentPage: page,
        };
        if (mode === 'test') params.mode = 'test';
        let mergedParams = Object.assign(activeInfo, params);
        const data = await GetPresaleAction(mergedParams);
        setPresales(data?.data);
        setList(data?.data);
        // setList()
        setTotalData(data?.totalResult?.total);

        setIsLoading(false);
      };
      getPre();
    };
    if (activatedTab == 'all_products' || activatedTab == 'quick_view') {
      setIsLoading(true);
      getPresaleFunction();
    } else {
      setPresales([]);
      if (isConnected) {
        getPresaleFunction();
      }
    }

    // Your code to fetch data or perform any other actions based on the query parameters
  }, [
    location.search,
    search?.current,
    activeTab,
    currentNetwork,
    isConnected,
    account.address,
    selectedValues,
  ]);

  const [alarms, setAlarms] = useState([]);

  const [alarmLoading, setalarmLoading] = useState();
  const getRemderPersale = async () => {
    try {
      setalarmLoading(true);
      const data = await getPresaleReminderDetails(account?.address);
      if (data) {
        setAlarms(data.alarms);
        setalarmLoading(false);
      }
    } catch (err) {
    } finally {
    }
  };

  useEffect(() => {
    if (account?.address) {
      getRemderPersale();
    }
  }, [account?.address, , account?.isConnected]);

  const DeleteAllRemnderPersale = async () => {
    const result = await deletePresalesReminder(account.address);
    if (result) {
      var button = document.getElementById('prevBtnClose');
      button.click();
    }
    toast.success(result?.data?.message);
    setAlarms([]);
  };

  const DeleteOneRemnderPersale = async (data) => {
    const result = await deleteonePresalesReminder(data);
    toast.success(result?.data?.message);
    getRemderPersale();
  };

  const handleChange = (page, filter, sort, network, tab) => {
    const searchParams = new URLSearchParams();
    searchParams.set('page', page || currentPage);
    searchParams.set('filterBy', filter || filterBy);
    searchParams.set('sortBy', sort || sortBy);
    searchParams.set('networks', network || currentNetwork);
    searchParams.set('tab', tab || activeTab);
    console.log(searchParams.toString(), 'searchparams');
    navigate(`${BASE_URL}presales?${searchParams.toString()}`, {
      replace: true,
    });
  };

  // Network

  // console.log(saleStatus, "saleStatus")
  const dispatch = useDispatch();
  const [currentUnixTime, setCurrentUnixTime] = useState(moment().unix());

  //REMINDER SECTION IN RESALE

  const [remindStatus, setRemindStatus] = useState('presale');

  const [selectedPresaleId, setSelectedPresaleId] = useState('');

  const onChangeValue = async (time, preSale, PresaleId) => {
    setSelectedPresaleId(PresaleId);

    if (account.isConnected == false) {
      toast.error('Login first', { toastId: 'loginFirstTid' });
    } else {
      let body = {
        pool: {
          chain: chain?.id,
          address: preSale,
          status: remindStatus,
          time: time,
        },
        subscription: {
          token: notificationToken,
        },
        wallet: account.address,
        saleType: 'Presale',
      };
      await reminderAction(body).then((response) => {
        if (response != undefined) {
          toast.success(
            `${
              response?.message || response?.data?.message
            } for ${time} minute!`
          );
          getRemderPersale();
        }
      });
    }
    //
  };
  const [showAdditionalData, setShowAdditionalData] = useState([]);

  const toggleAdditionalData = (key) => {
    setShowAdditionalData((prevState) => {
      const updatedState = [...prevState];
      updatedState[key] = !updatedState[key];
      return updatedState;
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentUnixTime(moment().unix());
    }, 2000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);
  // const [remindState, setRemindState] = useState(false);
  // const toggleRemindState = (event) => {

  //   setRemindState(!remindState);
  // };

  function getCheckedValueById(id) {
    const radioElement = document.getElementById(id);
    if (radioElement && radioElement.checked) {
      return radioElement.value;
    }
    return null; // If the radio button with the specified ID is not found or not checked
  }

  const isAlarmChecked = (preSale, key, time) => {
    return (
      alarms.filter((dta) => {
        return (
          dta?.address == preSale &&
          dta?.time === time &&
          (getCheckedValueById(`presale${key}`) === 'on'
            ? dta.status === 'presale'
            : dta?.status === 'listing')
        );
      }).length > 0
    );
  };

  const handleSearchDebounced = _lodash.debounce((value) => {
    search.current = value;
    // refetch();
  }, 500);

  const handleChangeSearch = ({ target }) => {
    // console.log("target.value",target.value)
    // // _search(target.value)
    // _lodash.debounce((value) => {
    //   _search(value);
    // }, 500);
    handleSearchDebounced(target.value);
  };

  // const [openFilter, setOpenFilter] = useState(false);

  document.title = APP_NAME_TITLE;

  return (
    <>
      {/* <div className="body-content side-bar"> */}
      <Container id='presalespage'>
        <Trending />
        <SaleLayout
          title='Presales'
          activeTab={activeTab}
          tabs={presaleTabs}
          onTabClick={setShowTab}
          // toggleFilter={() => {
          //   setOpenFilter(!openFilter);
          // }}
          renderSearch={
            !['quick_view', 'myalarms'].includes(activeTab) && (
              <SearchBar onSearch={handleChangeSearch} />
            )
          }
          renderFilter={
            !['quick_view', 'myalarms'].includes(activeTab) && (
              <>
                <div
                // className={`${
                //   openFilter ? 'presale-filter-model' : ''
                // }`}
                >
                  {/* heading */}
                  {/* <div className='px-3 d-flex justify-content-between align-items-start'>
                    <h6 className='fs-6 ff-poppins mb-4 text-gray'>Filters</h6>
                    <button
                      type='button'
                      onClick={() => setOpenFilter(false)}
                      className='btn-close btn-close-white d-block d-xl-none'
                      aria-label='Close'
                    ></button>
                  </div> */}
                  {/* applied filters */}
                  <div className='px-4'>
                    <h6
                      className='fw-semibold text-muted mb-4 fs-7'
                      style={
                        {
                          // fontSize: '14px',
                          // lineHeight: '21px',
                        }
                      }
                    >
                      Applied filters
                    </h6>
                    <div className='mt-2 d-flex flex-wrap gap-2 align-items-center justify-content-start'>
                      {filterBy && filterBy !== 'all' && (
                        <Chip
                          variant='outlined'
                          color='dark-300'
                          onClose={() => {
                            setFilterBy('all');
                            handleChange(
                              undefined,
                              'all',
                              undefined,
                              undefined
                            );
                          }}
                        >
                          {filterBy}
                        </Chip>
                      )}
                      {sortBy && sortBy !== 'no' && (
                        <Chip
                          variant='outlined'
                          color='dark-300'
                          onClose={() => {
                            setSortBy('no');
                            handleChange(undefined, undefined, 'no', undefined);
                          }}
                        >
                          {sortBy}
                        </Chip>
                      )}

                      {selectedValues?.map((value) => (
                        <Chip
                          variant='outlined'
                          color='dark-300'
                          onClose={() => {
                            // setSortBy('no');
                            const filteredValues = selectedValues.filter(
                              (item) => item !== value
                            );
                            setSelectedValues(filteredValues);
                            handleChange(
                              undefined,
                              undefined,
                              undefined,
                              filteredValues
                            );
                          }}
                        >
                          {
                            allOptions.find((option) => option.value === value)
                              .label
                          }
                        </Chip>
                      ))}
                    </div>
                  </div>

                  <hr />
                  {activeTab != 'my_contribution' && (
                    <>
                      <div className='px-4'>
                        <Filter
                          id='filterBy'
                          isSearchBar
                          label='Filter by'
                          data={presaleFilterByOptions}
                          onSelect={(option) => {
                            setFilterBy(option.value);
                            handleChange(
                              undefined,
                              option.value,
                              undefined,
                              undefined
                            );
                          }}
                        />
                      </div>

                      <hr />

                      <div className='px-4'>
                        <Filter
                          id='sortBy'
                          label='Sort by'
                          data={[
                            {
                              label: 'Start Time',
                              value: 'startTime',
                            },
                            {
                              label: 'End Time',
                              value: 'endTime',
                            },
                          ]}
                          onSelect={(option) => {
                            handleChange(
                              undefined,
                              undefined,
                              option.value,
                              undefined
                            );
                          }}
                        />
                      </div>
                      <hr />
                    </>
                  )}
                  <div className='px-4'>
                    <Filter
                      id='network'
                      isSearchBar
                      label='Network'
                      data={allOptions}
                      onSelect={(option) => {
                        if (!selectedValues.includes(option.value)) {
                          const data = new Set([
                            ...selectedValues,
                            option.value,
                          ]);
                          setSelectedValues(Array.from(data));
                          handleChange(
                            undefined,
                            undefined,
                            undefined,
                            Array.from(data)
                          );
                        }
                      }}
                      // renderOption={(option) => {
                      //   return (
                      //     <div>
                      //       <div className='d-flex gap-3 align-items-center'>
                      //         <input type='checkbox' className='select_checkbox'
                      //         checked={}
                      //         />
                      //         {option.label}
                      //       </div>
                      //     </div>
                      //   );
                      // }}
                    />
                  </div>
                </div>
              </>
            )
          }
        >
          <div className='tab-content'>
            <div className='tab-pane container' id='quickview'>
              {' '}
            </div>

            {activeTab == 'myalarms' ? (
              account.isConnected == false ? (
                <div className='d-flex justify-content-center w-100 fs-7'>
                  Please login with your wallet!
                </div>
              ) : alarmLoading ? (
                <div className='d-flex justify-content-center w-100 fs-7'>
                  <SyncLoader color='#3498DB' />
                </div>
              ) : (
                <div className='tab-pane active' id='allproducts'>
                  <div className='myalaram-removeall-btn d-flex align-items-center text-blue justify-content-end mb-4'>
                    <button
                      // className="remove-all-reminder"
                      disabled={alarms && alarms?.length == 0 ? true : false}
                      // onClick={DeleteAllRemnderPersale}
                      className='btn btn-block modal-open  remove-all-reminder'
                      href='#modal'
                      data-bs-toggle='modal'
                      data-bs-target='#cancelPresaleModal'
                    >
                      <i className='fa fa-times me-2' aria-hidden='true' />{' '}
                      Remove All
                    </button>
                  </div>
                  <h2 className='text-white text-center mb-4'>My Alarms</h2>
                  <div className='table-responsive'>
                    <table className='table my_alarms_table text-white'>
                      <thead>
                        <tr>
                          <th>Network</th>
                          <th>Name</th>
                          <th>Presale Start</th>
                          <th>Estimated Dex Listing </th>
                          <th>Alarm</th>
                          <th className='text-center'>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {alarms?.length > 0 ? (
                          <>
                            {alarms.map((data, key) => {
                              // Presale Start
                              const timestamp = data?.startTime;
                              const date = new Date(timestamp * 1000);
                              const formattedDateTime = moment(date).format(
                                'YYYY-MM-DD HH:mm:ss'
                              );
                              //Estimated Dex Listing
                              const Estimatedtimestamp =
                                data?.estimatedDexListingTime;
                              const Estimateddate = new Date(
                                Estimatedtimestamp * 1000
                              );
                              const EstimatedformattedDateTime = moment(
                                Estimateddate
                              ).format('YYYY-MM-DD HH:mm:ss');

                              const alarmTimetimestamp = new Date(data?.alarm);
                              const alarmDate = new Date(
                                alarmTimetimestamp * 1000
                              );
                              const alarmDateFormatedDateTime = moment(
                                alarmDate
                              ).format('YYYY-MM-DD HH:mm:ss');

                              return (
                                <>
                                  <tr key={key}>
                                    <td>
                                      <img
                                        src={networkImagesByChain[data?.chain]}
                                        className='img-fluid'
                                        alt='icon'
                                        width={40}
                                      />
                                    </td>
                                    <td className='d-flex align-items-start my_alarms_name_fild'>
                                      <div className='me-2 mt-2 card_second_section_img_alarm'>
                                        <span>
                                          <img
                                            alt='img'
                                            src={
                                              data?.logoUrl == ''
                                                ? 'images/bg2.png'
                                                : data?.logoUrl
                                            }
                                            onError={(event) => {
                                              event.target.src =
                                                'images/bg2.png';
                                              event.onError = null;
                                            }}
                                          />
                                        </span>
                                      </div>
                                      <div>
                                        <p className='mb-0 text-white'>
                                          {data?.name}
                                        </p>
                                        <span>{data?.token_symbol}</span>
                                      </div>
                                    </td>
                                    <td>
                                      {formattedDateTime}
                                      {/* 2023-01-11 <span>17:40</span> */}
                                    </td>
                                    <td>
                                      {EstimatedformattedDateTime}
                                      {/* 2023-12-15 <span>00:00</span> */}
                                    </td>
                                    <td>
                                      {/* {moment(data.created_at).format('YYYY-MM-DD HH:mm:ss')} */}
                                      {alarmDateFormatedDateTime}
                                      {/* {data?.created_at} */}
                                      {/* 2023-12-14 <span>23:45</span> */}
                                    </td>
                                    <td className='text-center'>
                                      <i
                                        onClick={(e) => {
                                          e.preventDefault();
                                          DeleteOneRemnderPersale(data?.id);
                                        }}
                                        className='fa fa-times'
                                        aria-hidden='true'
                                        style={{ cursor: 'pointer' }}
                                      />
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            <tr>
                              <td
                                colSpan='12'
                                className='no-data mt-4 pt-4 pb-4'
                              >
                                <div className='d-flex justify-content-center w-100 fs-7'>
                                  No Data
                                </div>
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )
            ) : (
              <div className='tab-pane active' id='allproducts'>
                <div
                  className={`row row-cols-1 ${
                    activeTab != 'quick_view'
                      ? 'row-cols-sm-2 row-cols-1 g-2'
                      : ''
                  }`}
                >
                  {/* {console.log(presales?.length > 0 ? presales[1].endTimes.split(',')[data?] : 'out')} */}
                  {
                    // !loading ? (
                    activeTab != 'quick_view' ? (
                      loading ? (
                        new Array(10).fill(0).map((item, idx) => (
                          <div className='col mb-4' key={idx}>
                            <PresaleCardShimmer />
                          </div>
                        ))
                      ) : presales?.length > 0 ? (
                        presales?.map((data, key) => {
                          let currentRound;

                          if (data?.endTimes) {
                            data?.endTimes
                              ?.split(',')
                              ?.forEach((endTime, index) => {
                                if (
                                  !currentRound &&
                                  endTime >= currentUnixTime
                                ) {
                                  currentRound = {
                                    roundNumber: index + 1,
                                    startTime:
                                      data.startTimes.split(',')[index],
                                    endTime,
                                  };
                                  return;
                                }
                              });
                          }
                          const startTime = Number(
                            currentRound?.startTime || 0
                          );
                          const endTime = Number(currentRound?.endTime || 0);
                          // const startTime = Number(data?.startTimes?.split(",")[0]);
                          // const endTime = Number(data?.  endTimes?.split(",")[data?.endTimes.split(",").length - 1]);
                          let classNameD =
                            'sale_upcoming badge text-bg-secondary';
                          let statusText = 'Upcoming';
                          let startEndIn = 'TBA';
                          if (data.is_approved === PRESALE_STATUS.pending) {
                            statusText = 'Pending';
                            classNameD =
                              'sale_upcoming badge text-bg-secondary';
                            startEndIn = 'Waiting for approval';
                          } else if (
                            data?.is_approved === PRESALE_STATUS.rejected
                          ) {
                            classNameD = 'sale_end badge text-bg-danger';
                            statusText = 'Sale Rejected';
                            startEndIn = `Rejected`;
                          } else {
                            if (
                              currentUnixTime >= startTime &&
                              endTime > currentUnixTime
                            ) {
                              classNameD = 'sale_live badge text-bg-success';
                              statusText = 'Sale Live';
                              startEndIn = 'Sale Ends In';
                            }

                            if (data?.isCanceled == 1) {
                              statusText = 'Sale Cancelled';
                              classNameD = 'sale_upcoming badge text-bg-danger';
                            } else if (data?.setSaleTime == 0) {
                              statusText = 'TBA';
                            } else if (data?.isFinalized == 1) {
                              if (
                                currentUnixTime >= data?.estimatedDexListingTime
                              ) {
                                if (data.listOnDex) {
                                  statusText = 'Listed on dex';
                                  startEndIn = 'Listing Time';
                                  classNameD =
                                    'listed_on_dex badge text-bg-info';
                                } else {
                                  statusText = 'Finalized';
                                  startEndIn = 'Finalized Time';
                                  classNameD =
                                    'listed_on_dex badge text-bg-success';
                                }
                              } else {
                                statusText = 'LIQ Unlocked';
                                startEndIn = 'Listing Time';
                                classNameD = 'liq_unlocked badge text-bg-info';
                              }
                            } else if (
                              currentUnixTime >= startTime &&
                              endTime > currentUnixTime
                            ) {
                              classNameD = 'sale_live badge text-bg-primary';
                              statusText = 'Sale Live';
                              startEndIn = 'Sale Ends In';
                            } else if (currentUnixTime < startTime) {
                              classNameD =
                                'sale_upcoming badge text-bg-secondary';
                              statusText = 'Upcoming';
                              startEndIn = 'Sale Start In';
                            } else if (currentUnixTime > endTime) {
                              statusText = 'Sale Ended';
                              classNameD = 'sale_end badge text-bg-success';
                              startEndIn = 'Sale Ended';
                            }
                          }

                          const hoverClass =
                            data?.perk === 0
                              ? 'Standard'
                              : data?.perk === 1
                              ? 'Gold'
                              : data.perk === 2
                              ? 'Platinum'
                              : 'Diamond';

                          const not_custom_then_symbol =
                            config.SUPPORTED_NETWORKS.find(
                              (network) => network.id == data?.chain
                            )?.symbol;

                          const fundRaisingTokenSymbol =
                            data?.fund_releasing_token == 'Custom' ||
                            data?.fund_releasing_token == 'BUSD'
                              ? data?.custom_fund_raising_token_symbol
                              : not_custom_then_symbol || '';
                          const fundRaisingTokenDecimal = Number(
                            data?.fund_releasing_token == 'Custom' ||
                              data?.fund_releasing_token == 'BUSD' ||
                              data?.fund_releasing_token == 'USDT'
                              ? data?.custom_fund_token_decimal
                              : 18
                          );

                          return (
                            <div className='col mb-3 mb-sm-4' key={key}>
                              <PresaleCard
                                index={key}
                                classNameD={classNameD}
                                currentUnixTime={currentUnixTime}
                                // FavouriteFunction={FavouriteFunction}
                                data={data}
                                endTime={endTime}
                                fundRaisedData={fundRaisedData}
                                fundRaisingTokenDecimal={
                                  fundRaisingTokenDecimal
                                }
                                fundRaisingTokenSymbol={fundRaisingTokenSymbol}
                                isAlarmChecked={isAlarmChecked}
                                onChangeValue={onChangeValue}
                                setRemindStatus={setRemindStatus}
                                showAdditionalData={showAdditionalData}
                                startEndIn={startEndIn}
                                startTime={startTime}
                                statusText={statusText}
                                toggleAdditionalData={toggleAdditionalData}
                                hoverClass={hoverClass}
                              />
                            </div>

                            // <div className='col mb-4' key={`presale-${key}`}>
                            //   <div
                            //     className={`card position-relative custom_card_design ${hoverClass}`}
                            //   >
                            //     {/* audit vetted kyc */}
                            //     <div
                            //       className='position-absolute'
                            //       style={{
                            //         top: 0,
                            //         right: '15px',
                            //       }}
                            //     >
                            //       <div className='audi_kyc_btn d-flex gap-1'>
                            //         {data?.isTeamVesting == 1 && (
                            //           <button
                            //             type='btn'
                            //             className='btn audit-btn'
                            //             onClick={(e) => {
                            //               if (
                            //                 data?.vetted != '' &&
                            //                 data?.vetted != null
                            //               ) {
                            //                 e.preventDefault();
                            //                 window.open(data?.vetted);
                            //               }
                            //             }}
                            //           >
                            //             Vetted
                            //           </button>
                            //         )}
                            //         {data?.perk !=
                            //           PerkCategories.STANDARD && (
                            //           <>
                            //             <button
                            //               type='btn'
                            //               className='btn audit-btn'
                            //               onClick={(e) => {
                            //                 if (
                            //                   data?.audit != '' &&
                            //                   data?.audit != null
                            //                 ) {
                            //                   e.preventDefault();
                            //                   window.open(data?.audit);
                            //                 }
                            //               }}
                            //             >
                            //               Audit
                            //             </button>
                            //             <button
                            //               type='btn'
                            //               className='btn kyc-btn'
                            //               onClick={(e) => {
                            //                 if (
                            //                   data?.kyc != '' &&
                            //                   data?.kyc != null
                            //                 ) {
                            //                   e.preventDefault();
                            //                   window.open(data?.kyc);
                            //                 }
                            //               }}
                            //             >
                            //               KYC+
                            //             </button>
                            //           </>
                            //         )}
                            //       </div>
                            //     </div>
                            //     <Link
                            //       to={`${BASE_URL}presale?presale_id=${data?.preSale}&chainId=${data?.chain}`}
                            //     >
                            //       <div
                            //         className={`custom_card d-flex flex-column ${
                            //           showAdditionalData[key] ? 'hide' : ''
                            //         }`}
                            //       >
                            //         {/* <Link to={`${BASE_URL}presale?presale_id=${data?.preSale}&chainId=${data?.chain}`}> */}
                            //         <div className='d-flex gap-4 p-3 w-100 align-items-start justify-content-start'>
                            //           <div
                            //             className='card-top-img stablz-card-top-img'
                            //             // style={{
                            //             //   backgroundImage: `url(${data?.bannerUrl != "" ? data?.bannerUrl : "https://testnet.gempad.app/static/bg4.png"})`,
                            //             // }}
                            //           >
                            //             <Banner url={data.bannerUrl} />
                            //             {/*  */}

                            //             <div className='card_second_section_img position'>
                            //               <img
                            //                 src={data?.logoUrl}
                            //                 // className="img-fluid"
                            //                 alt='logo'
                            //                 onError={(event) => {
                            //                   event.target.src =
                            //                     BASE_URL +
                            //                     `images/stablz_stablz_icon.png`;
                            //                   event.onerror = null;
                            //                 }}
                            //               />
                            //               <span>
                            //                 <img
                            //                   src={
                            //                     networkImagesByChain[
                            //                       data?.chain
                            //                     ]
                            //                   }
                            //                   // className="img-fluid"
                            //                   alt='logo'
                            //                   width={28}
                            //                 />
                            //               </span>
                            //             </div>
                            //           </div>
                            //           <div className='mt-1'>
                            //             <div className='card_second_section_text'>
                            //               <h5>
                            //                 {data?.sale_without_token == 1 &&
                            //                 data?.startTimes == '0'
                            //                   ? data?.sale_title
                            //                   : data?.launch_stealth_sale ==
                            //                     true
                            //                   ? data?.sale_title
                            //                   : data?.name}
                            //               </h5>
                            //               <p className='mb-1'>
                            //                 {data?.sale_without_token == 1
                            //                   ? `Without Token ${
                            //                       SALE_TYPE.isPresaleOrPrivateSale(data?.saleType)
                            //                         ? ''
                            //                         : data?.saleType ==
                            //                           SALE_TYPE.HYPER
                            //                         ? '(Hyper)'
                            //                         : '(Fair)'
                            //                     }`
                            //                   : data?.launch_stealth_sale == 1
                            //                   ? `Stealth ${data?.saleType}`
                            //                   : data?.saleType}{' '}
                            //                 - Max:{' '}
                            //                 {/* {web3.utils.fromWei(
                            //               String(data?.data?.maxBuy),
                            //               "ether"
                            //             )}{" "} */}
                            //                 {SALE_TYPE.isPresaleOrPrivateSale(data?.saleType)
                            //                   ? ethers.utils.formatUnits(
                            //                       String(data?.maxBuy),
                            //                       fundRaisingTokenDecimal
                            //                     )
                            //                   : data?.isMaxBuy == 1
                            //                   ? ethers.utils.formatUnits(
                            //                       String(data?.maxBuy),
                            //                       fundRaisingTokenDecimal
                            //                     )
                            //                   : '∞'}{' '}
                            //                 {fundRaisingTokenSymbol}
                            //               </p>

                            //               {/* countdown */}
                            //               {data.is_approved ===
                            //               PRESALE_STATUS.approved ? (
                            //                 <div>
                            //                   {fundRaisedData != undefined &&
                            //                   Number(
                            //                     ethers.utils.formatUnits(
                            //                       String(
                            //                         fundRaisedData[key]
                            //                           ?.result
                            //                       ),
                            //                       Number(
                            //                         fundRaisingTokenDecimal
                            //                       )
                            //                     )
                            //                   ) <
                            //                     Number(
                            //                       ethers.utils.formatUnits(
                            //                         String(data?.softCap),
                            //                         Number(
                            //                           fundRaisingTokenDecimal
                            //                         )
                            //                       )
                            //                     ) &&
                            //                   currentUnixTime > endTime &&
                            //                   data?.isCanceled == 0 &&
                            //                   data?.setSaleTime == 1 ? ( //eslint-disable-line no-undef
                            //                     'Sale Failed'
                            //                   ) : startEndIn ==
                            //                     'Sale Ended' ? (
                            //                     <>
                            //                       <div>Sale Ended</div>
                            //                     </>
                            //                   ) : (
                            //                     <>
                            //                       {data?.isCanceled != 1 && (
                            //                         <p className='mb-1'>
                            //                           {startEndIn == 'TBA'
                            //                             ? 'Sale Starts In'
                            //                             : startEndIn}
                            //                         </p>
                            //                       )}
                            //                       {data?.isCanceled === 1 ? (
                            //                         'Cancelled'
                            //                       ) : data?.isFinalized ===
                            //                           1 &&
                            //                         moment(
                            //                           new Date()
                            //                         ).unix() >
                            //                           data?.estimatedDexListingTime ? (
                            //                         moment(
                            //                           data?.estimatedDexListingTime *
                            //                             1000
                            //                         ).format('DD-MM-YYYY')
                            //                       ) : data?.setSaleTime ===
                            //                         1 ? (
                            //                         <>
                            //                           {Number(
                            //                             moment(
                            //                               new Date()
                            //                             ).unix()
                            //                           ) <
                            //                             data?.estimatedDexListingTime &&
                            //                           data?.isFinalized ==
                            //                             1 ? (
                            //                             <span>
                            //                               <PersaleCountDown
                            //                                 unixTime={
                            //                                   data?.estimatedDexListingTime
                            //                                 }
                            //                               />
                            //                             </span>
                            //                           ) : (
                            //                             <>
                            //                               <PersaleCountDown
                            //                                 unixTime={
                            //                                   Number(
                            //                                     moment(
                            //                                       new Date()
                            //                                     ).unix()
                            //                                   ) >=
                            //                                   Number(
                            //                                     startTime
                            //                                   )
                            //                                     ? endTime
                            //                                     : startTime
                            //                                 }
                            //                               />
                            //                             </>
                            //                           )}
                            //                         </>
                            //                       ) : (
                            //                         <>
                            //                           {data?.isCanceled ===
                            //                             0 && (
                            //                             <p className='mb-1'>
                            //                               {startEndIn}
                            //                             </p>
                            //                           )}
                            //                           {startEndIn ===
                            //                             'Sale Ended' && (
                            //                             <div>Sale Ended</div>
                            //                           )}
                            //                         </>
                            //                       )}
                            //                     </>
                            //                   )}
                            //                 </div>
                            //               ) : data?.is_approved ===
                            //                 PRESALE_STATUS.rejected ? (
                            //                 <div className='text-center'>
                            //                   Rejected
                            //                 </div>
                            //               ) : (
                            //                 <div className='text-center'>
                            //                   Waiting for approval
                            //                 </div>
                            //               )}

                            //               {data.isWhiteList == 'true' &&
                            //               data?.whitelistContestUrl != '' ? (
                            //                 <>
                            //                   <div className='d-flex align-items-center gap-1'>
                            //                     <button
                            //                       className='white_list_button'
                            //                       onClick={(event) => {
                            //                         event.preventDefault();
                            //                         window.open(
                            //                           data?.whitelistContestUrl
                            //                         );
                            //                       }}
                            //                     >
                            //                       whitelist
                            //                     </button>
                            //                   </div>
                            //                 </>
                            //               ) : null}
                            //             </div>
                            //           </div>
                            //         </div>
                            //         <div className={`p-3 w-100`}>
                            //           {/* <div className='card_second_section d-flex justify-content-between align-items-end'> */}
                            //           {/* <div className='card_second_section_text'>
                            //               <h5>
                            //                 {data?.sale_without_token == 1 &&
                            //                 data?.startTimes == '0'
                            //                   ? data?.sale_title
                            //                   : data?.launch_stealth_sale ==
                            //                     true
                            //                   ? data?.sale_title
                            //                   : data?.name}
                            //               </h5>
                            //               <p>
                            //                 {data?.sale_without_token == 1
                            //                   ? `Without Token ${
                            //                       SALE_TYPE.isPresaleOrPrivateSale(data?.saleType)
                            //                         ? ''
                            //                         : data?.saleType ==
                            //                           SALE_TYPE.HYPER
                            //                         ? '(Hyper)'
                            //                         : '(Fair)'
                            //                     }`
                            //                   : data?.launch_stealth_sale == 1
                            //                   ? `Stealth ${data?.saleType}`
                            //                   : data?.saleType}{' '}
                            //                 - Max:{' '}
                            //                 {SALE_TYPE.isPresaleOrPrivateSale(data?.saleType)
                            //                   ? ethers.utils.formatUnits(
                            //                       String(data?.maxBuy),
                            //                       fundRaisingTokenDecimal
                            //                     )
                            //                   : data?.isMaxBuy == 1
                            //                   ? ethers.utils.formatUnits(
                            //                       String(data?.maxBuy),
                            //                       fundRaisingTokenDecimal
                            //                     )
                            //                   : '∞'}{' '}
                            //                 {fundRaisingTokenSymbol}
                            //               </p>
                            //             </div> */}
                            //           {/* <div className='card_second_section_img position'>
                            //               <img
                            //                 src={data?.logoUrl}
                            //                 // className="img-fluid"
                            //                 alt='logo'
                            //                 onError={(event) => {
                            //                   event.target.src =
                            //                     BASE_URL +
                            //                     `images/stablz_stablz_icon.png`;
                            //                   event.onerror = null;
                            //                 }}
                            //               />
                            //               <span>
                            //                 <img
                            //                   src={
                            //                     networkImagesByChain[
                            //                       data?.chain
                            //                     ]
                            //                   }
                            //                   // className="img-fluid"
                            //                   alt='logo'
                            //                   width={28}
                            //                 />
                            //               </span>
                            //             </div> */}
                            //           {/* </div> */}
                            //           <div className='progress_bar mt-2'>
                            //             <div className='d-flex justify-content-between align-items-baseline'>
                            //               <p className='mb-0'>Progress</p>
                            //               <p className='mb-0'>
                            //                 {fundRaisedData != undefined &&
                            //                 fundRaisedData[key]?.result
                            //                   ? Number(
                            //                       (ethers.utils.formatUnits(
                            //                         String(
                            //                           fundRaisedData[key]
                            //                             ?.result || 0
                            //                         ),
                            //                         Number(
                            //                           fundRaisingTokenDecimal
                            //                         )
                            //                       ) *
                            //                         100) /
                            //                         Number(
                            //                           ethers.utils.formatUnits(
                            //                             String(
                            //                               data?.hardCap || 0
                            //                             ),
                            //                             Number(
                            //                               fundRaisingTokenDecimal
                            //                             )
                            //                           )
                            //                         )
                            //                     )
                            //                   : 0}
                            //                 %
                            //               </p>
                            //             </div>
                            //             {/* <div
                            //         className="progress"
                            //         style={{ height: 10 }}
                            //       >
                            //         <div
                            //           className="progress-bar"
                            //           style={{
                            //             width: `${fundRaisedData != undefined && Number(formatEther(fundRaisedData[key]?.result) * 100 / Number(formatEther(data?.hardCap)))}%`,
                            //             height: 10,
                            //           }}
                            //         />
                            //       </div> */}
                            //             {SALE_TYPE.isPresaleOrPrivateSale(data?.saleType) ? (
                            //               <Progressbar
                            //                 fundRaisedData={
                            //                   fundRaisedData != undefined &&
                            //                   fundRaisedData[key]?.result
                            //                     ? fundRaisedData[key]?.result
                            //                     : 0
                            //                 }
                            //                 hardCap={data?.hardCap}
                            //                 fund_releasing_token={
                            //                   data?.fund_releasing_token
                            //                 }
                            //                 fundRaisingTokenDecimal={
                            //                   fundRaisingTokenDecimal
                            //                 }
                            //                 fundRaisingTokenSymbol={
                            //                   fundRaisingTokenSymbol
                            //                 }
                            //                 perk={data?.perk}
                            //               />
                            //             ) : (
                            //               <FairPorgressbar
                            //                 fundRaisedData={
                            //                   fundRaisedData != undefined &&
                            //                   fundRaisedData[key]?.result
                            //                     ? fundRaisedData[key]?.result
                            //                     : 0
                            //                 }
                            //                 hardCap={data?.hardCap}
                            //                 softCap={data?.softCap}
                            //                 token_decimals={
                            //                   data?.token_decimals
                            //                 }
                            //                 custom_fund_token_decimal={
                            //                   data?.custom_fund_token_decimal
                            //                 }
                            //                 isHardCap={data?.isHardCap}
                            //                 fundRaisingTokenDecimal={
                            //                   fundRaisingTokenDecimal
                            //                 }
                            //                 fundRaisingTokenSymbol={
                            //                   fundRaisingTokenSymbol
                            //                 }
                            //                 fund_releasing_token={
                            //                   data?.fund_releasing_token
                            //                 }
                            //                 perk={data?.perk}
                            //               />
                            //             )}

                            //             {SALE_TYPE.isPresaleOrPrivateSale(data?.saleType) ? (
                            //               <PresaleProgInfo
                            //                 fundRaisedData={
                            //                   fundRaisedData != undefined
                            //                     ? fundRaisedData[key]?.result
                            //                     : 0
                            //                 }
                            //                 fund_releasing_token={
                            //                   data?.fund_releasing_token
                            //                 }
                            //                 fundRaisingTokenDecimal={
                            //                   fundRaisingTokenDecimal
                            //                 }
                            //                 fundRaisingTokenSymbol={
                            //                   fundRaisingTokenSymbol
                            //                 }
                            //                 hardCap={data?.hardCap}
                            //               />
                            //             ) : (
                            //               <FairProgInfo
                            //                 fundRaisedData={
                            //                   fundRaisedData != undefined
                            //                     ? fundRaisedData[key]?.result
                            //                     : 0
                            //                 }
                            //                 fund_releasing_token={
                            //                   data?.fund_releasing_token
                            //                 }
                            //                 fundRaisingTokenDecimal={
                            //                   fundRaisingTokenDecimal
                            //                 }
                            //                 fundRaisingTokenSymbol={
                            //                   fundRaisingTokenSymbol
                            //                 }
                            //                 hardCap={data?.hardCap}
                            //                 isHardCap={data?.isHardCap}
                            //                 softCap={data?.softCap}
                            //               />
                            //             )}

                            //             {/* <div className="d-flex justify-content-between align-items-center">
                            //         <p>

                            //           {
                            //             fundRaisedData != undefined && ethers.utils.formatUnits(String(fundRaisedData[key]?.result), Number(data?.fund_releasing_token == 'Custom' || fundRaisingTokenDecimal)) || 0
                            //           }
                            //           {" "}
                            //           {
                            //             fundRaisingTokenSymbol
                            //           }

                            //         </p>
                            //         <p>
                            //           {
                            //             ethers.utils.formatUnits(String(data?.hardCap), Number(fundRaisingTokenDecimal))
                            //           }
                            //           {" "}
                            //           {
                            //             fundRaisingTokenSymbol
                            //           }
                            //         </p>
                            //       </div> */}
                            //           </div>
                            //           <div className='row row-cols-2 row-cols-sm-2 gx-2 gy-2'>
                            //             <div className='col'>
                            //               <div className='Softcap'>
                            //                 <p
                            //                   style={{
                            //                     whiteSpace: 'nowrap',
                            //                     textOverflow: 'ellipsis',
                            //                   }}
                            //                   className='justify-content-between d-flex  align-items-center flex-wrap'
                            //                 >
                            //                   Softcap
                            //                   <span>
                            //                     {' '}
                            //                     {Intl.NumberFormat('en', {
                            //                       notation: 'compact',
                            //                     }).format(
                            //                       ethers.utils.formatUnits(
                            //                         String(data?.softCap),
                            //                         Number(
                            //                           fundRaisingTokenDecimal
                            //                         )
                            //                       )
                            //                     )}{' '}
                            //                     {fundRaisingTokenSymbol}
                            //                   </span>
                            //                 </p>
                            //               </div>
                            //             </div>
                            //             <div className='col'>
                            //               <div className='Softcap'>
                            //                 <p
                            //                   style={{
                            //                     whiteSpace: 'nowrap',
                            //                     textOverflow: 'ellipsis',
                            //                   }}
                            //                   className='justify-content-between d-flex align-items-center flex-wrap'
                            //                 >
                            //                   Hardcap
                            //                   <span>
                            //                     {/* {web3.utils.fromWei(
                            //                   String(data?.data?.hardCap),
                            //                   "ether"
                            //                 )}{" "} */}
                            //                     {data?.isHardCap == 0 ? (
                            //                       <img
                            //                         src='images/icon/infinity.png'
                            //                         alt='infinity'
                            //                         className='img-fluid me-2'
                            //                       />
                            //                     ) : (
                            //                       `${Intl.NumberFormat('en', {
                            //                         notation: 'compact',
                            //                       }).format(
                            //                         ethers.utils.formatUnits(
                            //                           String(data?.hardCap),
                            //                           Number(
                            //                             fundRaisingTokenDecimal
                            //                           )
                            //                         )
                            //                       )} `
                            //                     )}
                            //                     {/* {" "} */}
                            //                     {fundRaisingTokenSymbol}
                            //                   </span>
                            //                 </p>
                            //               </div>
                            //             </div>
                            //             {!!data?.listOnDex && (
                            //               <>
                            //                 <div className='col'>
                            //                   <div className='Softcap'>
                            //                     <p
                            //                       style={{
                            //                         whiteSpace: 'nowrap',
                            //                         textOverflow: 'ellipsis',
                            //                       }}
                            //                       className='justify-content-between d-flex align-items-center flex-wrap'
                            //                     >
                            //                       Liquidity
                            //                       <span>
                            //                         {
                            //                           data?.dexLiquidityPercentage
                            //                         }
                            //                         %
                            //                       </span>
                            //                     </p>
                            //                   </div>
                            //                 </div>
                            //                 <div className='col'>
                            //                   <div className='Softcap'>
                            //                     <p
                            //                       style={{
                            //                         whiteSpace: 'nowrap',
                            //                         textOverflow: 'ellipsis',
                            //                       }}
                            //                       className='justify-content-between d-flex align-items-center flex-wrap'
                            //                     >
                            //                       Locked
                            //                       <Tooltip
                            //                         title={`${data?.liquidityLockupDays} Days`}
                            //                         placement='top-start'
                            //                         enterTouchDelay={false}
                            //                         onClick={(e) => {
                            //                           e.preventDefault();
                            //                         }}
                            //                       >
                            //                         <span>
                            //                           {data
                            //                             ?.liquidityLockupDays
                            //                             ?.length > 13
                            //                             ? `${data?.liquidityLockupDays.slice(
                            //                                 0,
                            //                                 3
                            //                               )}...${data?.liquidityLockupDays.slice(
                            //                                 -2
                            //                               )}`
                            //                             : data?.liquidityLockupDays}{' '}
                            //                           Days
                            //                         </span>
                            //                       </Tooltip>
                            //                     </p>
                            //                   </div>
                            //                 </div>
                            //               </>
                            //             )}
                            //           </div>
                            //           {/* <hr /> */}
                            //           {/* <div className='row'>
                            //             <div className='col'> */}
                            //         </div>

                            //         <div className='sale_status d-flex justify-content-end align-items-center gap-3 mt-auto p-3 w-100'>
                            //           <div className='card_notification d-flex align-items-center gap-1'>
                            //             {/* <button className="map-point-sm"
                            //                     onClick={(event) => {
                            //                       event.preventDefault();
                            //                       toggleAdditionalData(key)
                            //                     }}

                            //                   >
                            //                     <i class="fa fa-bell" aria-hidden="true"></i>
                            //                   </button> */}
                            //             <button
                            //               // disabled={activeTab == 'my_favorites'}
                            //               onClick={(event) => {
                            //                 event.preventDefault();
                            //                 FavouriteFunction(
                            //                   data?.presale_id,
                            //                   data?.isFavourite == '1'
                            //                     ? false
                            //                     : true
                            //                 );
                            //               }}
                            //               style={{
                            //                 // backgroundColor: `${
                            //                 //   data?.isFavourite == '1'
                            //                 //     ? 'red'
                            //                 //     : ''
                            //                 // }`,
                            //                 border:
                            //                   data?.isFavourite == '1'
                            //                     ? '1px solid var(--clr-danger)'
                            //                     : '1px solid #303A46',
                            //               }}
                            //             >
                            //               {console.log(
                            //                 data?.isFavourite,
                            //                 data?.presale_id,
                            //                 'isFabo'
                            //               )}
                            //               <i
                            //                 className={
                            //                   data?.isFavourite == '1'
                            //                     ? 'fa fa-heart'
                            //                     : 'fa fa-heart-o'
                            //                 }
                            //                 aria-hidden='true'
                            //                 // style={{ color: "" }}
                            //                 style={{
                            //                   color: `${
                            //                     data?.isFavourite == '1'
                            //                       ? 'var(--clr-danger)'
                            //                       : '#808195'
                            //                   }`,
                            //                 }}
                            //                 id={`heart-button-${data?.presale_id}`}
                            //               />
                            //             </button>
                            //           </div>
                            //           <div className={classNameD}>
                            //             {statusText}
                            //           </div>
                            //         </div>
                            //         {/* </div>
                            //           </div> */}

                            //         {/* {showAdditionalData[key] && */}

                            //         {/* } */}
                            //       </div>
                            //     </Link>
                            //     <div
                            //       className={`remind_section ${
                            //         showAdditionalData[key] ? 'show' : 'hide'
                            //       }`}
                            //       onClick={(e) => e.stopPropagation()}
                            //     >
                            //       {/* <div className={`remind_section ${showAdditionalData[key] ? 'show' : 'hide'}`}> */}
                            //       <div className='map-container'>
                            //         <div className='inner-basic division-details'>
                            //           <div className='darwin'>
                            //             <button
                            //               className='map-point-sm'
                            //               onClick={(event) => {
                            //                 event.preventDefault();
                            //                 toggleAdditionalData(key);
                            //               }}
                            //               data-show='.darwin'
                            //             >
                            //               <i
                            //                 className='fa fa-arrow-left me-2'
                            //                 aria-hidden='true'
                            //               />
                            //               Back
                            //             </button>
                            //             <div className='d-flex align-items-center justify-content-center remind_section_mian_height'>
                            //               <div className='remind_section_mian text-center'>
                            //                 <div className='remind_section'>
                            //                   <h4 className='text-white'>
                            //                     REMIND ME
                            //                   </h4>

                            //                   <div className='mt-3'>
                            //                     <input
                            //                       type='checkbox'
                            //                       name={`options${data.presale_id}`}
                            //                       onChange={(e) => {
                            //                         e.preventDefault();
                            //                         onChangeValue(
                            //                           30,
                            //                           data?.preSale,
                            //                           data?.presale_id
                            //                         );
                            //                       }}
                            //                       className='btn-check'
                            //                       id={`30min5${key}`}
                            //                       // checked={alarms.filter((dta) => {return dta?.address == data?.preSale && dta?.time == '30'&& (getCheckedValueById(`presale${key}`)=='on'? dta.status=='presale': dta?.status=='listing') }).length > 0}
                            //                       checked={isAlarmChecked(
                            //                         data?.preSale,
                            //                         key,
                            //                         '30'
                            //                       )}
                            //                     />
                            //                     <label
                            //                       className='btn btn-secondary'
                            //                       htmlFor={`30min5${key}`}
                            //                     >
                            //                       30min
                            //                     </label>
                            //                     <input
                            //                       type='checkbox'
                            //                       onChange={(e) => {
                            //                         e.preventDefault();
                            //                         onChangeValue(
                            //                           15,
                            //                           data?.preSale,
                            //                           data?.presale_id
                            //                         );
                            //                       }}
                            //                       className='btn-check'
                            //                       name={`options${data.presale_id}`}
                            //                       id={`15min${key}`}
                            //                       checked={isAlarmChecked(
                            //                         data?.preSale,
                            //                         key,
                            //                         '15'
                            //                       )}
                            //                     />
                            //                     <label
                            //                       className='btn btn-secondary'
                            //                       htmlFor={`15min${key}`}
                            //                     >
                            //                       15min
                            //                     </label>
                            //                     <input
                            //                       type='checkbox'
                            //                       onChange={(e) => {
                            //                         e.preventDefault();
                            //                         onChangeValue(
                            //                           5,
                            //                           data?.preSale,
                            //                           data?.presale_id
                            //                         );
                            //                       }}
                            //                       className='btn-check'
                            //                       name={`options${data.presale_id}`}
                            //                       id={`5min${key}`}
                            //                       checked={isAlarmChecked(
                            //                         data?.preSale,
                            //                         key,
                            //                         '5'
                            //                       )}
                            //                     />
                            //                     <label
                            //                       className='btn btn-secondary'
                            //                       htmlFor={`5min${key}`}
                            //                     >
                            //                       5min
                            //                     </label>
                            //                     {/* setRemindStatus? */}
                            //                   </div>
                            //                 </div>
                            //                 <div className='before_section mt-5'>
                            //                   <h4 className='text-white'>
                            //                     BEFORE
                            //                   </h4>
                            //                   <div className='mt-3'>
                            //                     <input
                            //                       type='radio'
                            //                       className='btn-check'
                            //                       // name="options55"
                            //                       name={`options5${key}`}
                            //                       id={`presale${key}`}
                            //                       autoComplete='off'
                            //                       onChange={() => {
                            //                         setRemindStatus(
                            //                           'presale'
                            //                         );
                            //                       }}
                            //                       defaultChecked
                            //                       // checked={remindStatus=='presale'}  //remove (unique selection)
                            //                     />
                            //                     <label
                            //                       className='btn btn-secondary'
                            //                       htmlFor={`presale${key}`}
                            //                     >
                            //                       Presale
                            //                     </label>
                            //                     <input
                            //                       type='radio'
                            //                       className='btn-check'
                            //                       name={`options5${key}`}
                            //                       id={`dexlisting${key}`}
                            //                       onChange={() => {
                            //                         setRemindStatus(
                            //                           'listing'
                            //                         );
                            //                       }}
                            //                       //  id="dexlisting5"
                            //                       autoComplete='off'
                            //                       // checked={remindStatus=='listing'}  //remove (unique selection)
                            //                     />
                            //                     <label
                            //                       className='btn btn-secondary'
                            //                       htmlFor={`dexlisting${key}`}
                            //                     >
                            //                       Dex Listing
                            //                     </label>
                            //                   </div>
                            //                 </div>
                            //               </div>
                            //             </div>
                            //           </div>
                            //           <div className='ptown5 hide'></div>
                            //         </div>
                            //       </div>
                            //     </div>
                            //   </div>
                            // </div>
                          );
                        })
                      ) : ((!isConnected &&
                          activeTab == 'my_created_presale') ||
                          activeTab == 'my_favorites' ||
                          activeTab == 'my_contribution') &&
                        account.isConnected == false ? (
                        <div className='d-flex justify-content-center w-100 fs-7'>
                          Please login with your wallet!
                        </div>
                      ) : (
                        <div className='d-flex justify-content-center w-100 fs-7'>
                          No Data
                        </div>
                      )
                    ) : (
                      <>
                        <QuickView
                          presales={presales}
                          fundRaisedData={fundRaisedData}
                          loading={loading}
                        />
                      </>
                    )
                    // ) : (
                    //   <div className='d-flex justify-content-center w-100 fs-7'>
                    //     <SyncLoader color='#3498DB' />
                    //   </div>
                    // )
                  }
                </div>

                {!loading &&
                  presales?.length > 0 &&
                  totalData > itemsPerPage && (
                    <Pagination
                      className='pagination-bar'
                      currentPage={currentPage}
                      totalCount={totalData}
                      pageSize={itemsPerPage}
                      onPageChange={(page) => handlePageChange(page)}
                    />
                  )}

                {!loading && presales?.length == 0 && currentPage != 1 && (
                  <>
                    <PaginationOver
                      className='pagination-bar'
                      currentPage={currentPage}
                      totalCount={totalData}
                      pageSize={itemsPerPage}
                      onPageChange={(page) => handlePageChange(page)}
                    />
                  </>
                )}
              </div>
            )}

            <div className='tab-pane container fade' id='mycontribution'>
              {' '}
            </div>
            <div className='tab-pane active' id='myfavorites'>
              {' '}
            </div>

            <div className='tab-pane container fade' id='mycreatedpresales'>
              {' '}
            </div>
          </div>
        </SaleLayout>
      </Container>
      {/* </div> */}
      <div className='modal' id='cancelPresaleModal'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            {/* Modal Header */}
            <div className='modal-header'>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                hidden={false}
              />
            </div>
            {/* Modal body */}
            <div className='modal-body'>
              <div id='myDropdown' className='dropdown-content px-2 py-4'>
                <h5 className='text-center text-blue mb-4'>Are you sure?</h5>
                <div className='row row-cols-1 row-cols-sm-2'>
                  <div className='col'>
                    <label className='fieldlabels'>
                      All alarms will be removed.
                    </label>
                  </div>
                  <div style={{ overflow: 'auto' }}>
                    <div className='d-flex gap-3 pt-4 py-2 justify-content-center'>
                      <button
                        type='button'
                        id='prevBtnClose'
                        className='btn btn-danger m-0'
                        data-bs-dismiss='modal'
                      >
                        Cancel
                      </button>
                      <button
                        type='submit'
                        id='nextBtn'
                        className='btn btn-primary m-0'
                        onClick={DeleteAllRemnderPersale}
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class='clearfix'></div>
    </>
  );
}
